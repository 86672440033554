import React from 'react';
import { withPrefix } from 'gatsby';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';

import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import link from '../theme/styles/link';
import footerChevron from '../theme/styles/footerChevron';
import Link from '../components/Link';

const styles = () => ({
  ...container,
  ...section,
  ...link,
  ...footerChevron,
});

const Privacy = ({ classes, location }) => (
  <Layout location={location} title="Privacy Policy">
    <div className={classNames(classes.container, classes.section)}>
      <Typography component="h1" variant="h1">
        Privacy Policy
      </Typography>
      <Typography component="p" variant="subtitle2">
        Last updated: May 11, 2018
      </Typography>

      <Typography component="p" variant="subtitle1">
        The purpose of this website is to provide visitors with useful
        information and allow them the opportunity to request additional
        information regarding moving/relocation needs. Our visitors have the
        right to be aware of any information collected and used, and under what
        circumstances, if any, information is disclosed.
      </Typography>

      <Typography component="h2" variant="h2">
        General Privacy and Data Responsibility
      </Typography>

      <Typography component="p" variant="subtitle1">
        TWO MEN AND A TRUCK does not sell or share information collected about
        you or the services you are requesting. Any information collected,
        regarding you or the services requested, will only be used to provide
        information or perform the services requested. We thoroughly vet any
        third-party services we utilize to ensure they will treat your
        information with the respect you deserve.
      </Typography>

      <Typography component="h3" variant="h3">
        Information Collection and Use
      </Typography>

      <Typography component="p" variant="subtitle1">
        Registration is not required to enter our site.
      </Typography>

      <Typography component="p" variant="subtitle1">
        We respect each website visitor's right to personal privacy. To that
        end, we collect and use information throughout our website only as
        disclosed in this privacy policy. This statement applies solely to
        information collected on this website.
      </Typography>

      <Typography component="p" variant="subtitle1">
        For each visitor to our website, the web server automatically recognizes
        the visitor's domain name only; it does not recognize email addresses
        and does not uniquely identify the individual or other identifiable
        details. The information we collect is used for internal review and to
        improve or enhance our website’s content. Under no circumstances is data
        shared with third-party organizations for commercial purposes.
      </Typography>

      <Typography component="p" variant="subtitle1">
        Individuals may choose to submit either estimate request forms or
        customer service inquiry forms. During completion of these forms, users
        must include certain contact information. This information is never sold
        or shared with any third-party organizations. It is gathered only for
        the purposes of contacting visitors regarding services for which
        interest has been expressed.
      </Typography>

      <Typography component="p" variant="subtitle1">
        We do not have an order form. We have appropriate security measures in
        place in our physical facilities to protect against the loss, misuse, or
        alteration of information collected from our website.
      </Typography>

      <Typography component="p" variant="subtitle1">
        From time to time, we engage our visitors through online surveys. On
        these occasions, we do not sell or share our subscribers’ email
        addresses.
      </Typography>

      <Typography component="h3" variant="h3">
        Notification of CHANGES
      </Typography>

      <Typography component="p" variant="subtitle1">
        We reserve the right to revise our privacy policy at any time without
        advance notice. Any change to this policy will be posted on this site
        and the new policy will apply only to information collected following
        the posting of the new policy.
      </Typography>

      <Typography component="h3" variant="h3">
        Move Quote Form Information
      </Typography>

      <Typography component="p" variant="subtitle1">
        We provide visitors to our website with the ability to enter their move
        details and personal information to initiate a quote for our services.
        We store this information, both in your browser while you complete the
        form and in our internal systems after submission, and use it to
        estimate and provide services. The information stored in your browser is
        stored for the purpose of improving your experience while interacting
        with our web form by having the ability to recall specific information
        when choosing to go back and edit information before submitting. By
        entering your information, you are willingly sharing personal
        information with us to be used as referenced in the General Privacy and
        Data Responsibility section of this policy. Once the form is completed
        and submitted, all information stored in your browser is automatically
        cleared. Your local storage can also be cleared at any time through your
        browser settings.
      </Typography>

      <Typography component="h3" variant="h3">
        Chat Feature
      </Typography>

      <Typography component="p" variant="subtitle1">
        TWO MEN AND A TRUCK processes and stores personal data within the online
        chat feature for the purpose of providing support via chat. You have the
        right to request your personal information collected be deleted, and you
        may obtain a copy of any personal data stored in the chat database by
        contacting us via the steps outlined in the DELETING YOUR PERSONAL
        INFORMATION section of this policy.
      </Typography>

      <Typography component="h3" variant="h3">
        Email Communications
      </Typography>

      <Typography component="p" variant="subtitle1">
        By providing your contact and other information to TWO MEN AND A TRUCK®
        or through this Site, you have given your express consent for TWO MEN
        AND A TRUCK® and its franchisees to use your personal information as
        outlined in this Privacy Notice. To the extent that applicable law
        requires express consent, express written consent, or “opt-in” for the
        collection and use of certain types of personal information, TWO MEN AND
        A TRUCK® will maintain processes and procedures to ensure such
        information is collected with express consent. Each communication that
        potential customers receive from TWO MEN AND A TRUCK® will give
        instructions on opting out or unsubscribing from future email
        communications.
      </Typography>

      <Typography component="h3" variant="h3">
        Security and Safeguards
      </Typography>

      <Typography component="p" variant="subtitle1">
        The security of your information is very important to TWO MEN AND A
        TRUCK®. TWO MEN AND A TRUCK uses appropriate administrative, technical,
        personnel, and physical safeguards to protect the security of your
        personal information. TWO MEN AND A TRUCK also makes all attempts to
        ensure only necessary people and third parties have access to personal
        information in order to safeguard your personal information against
        loss, theft, and unauthorized use or modification. TWO MEN AND A TRUCK
        uses some of the most advanced Internet security technology available
        today to protect your information. TWO MEN AND A TRUCK practices
        industry standards and frameworks for securing data systems and the data
        that is stored.
      </Typography>

      <Typography component="h3" variant="h3">
        Tracking and Storage of Information
      </Typography>

      <Typography component="p" variant="subtitle1">
        If you choose to opt-in to our tracking policy displayed on every page
        of our website, we will utilize cookies to enhance the user experience
        while on our site. This includes the use of location selection cookies
        to eliminate the requirement of subsequent selection when returning or
        viewing apps or other pages of the website. Remarketing/retargeting
        cookies are also utilized to display advertisements on other third-party
        websites based on visits. All remarketing/retargeting tracking abides by
        guidelines outlined with that specific ad provider network. A session
        value is assigned while visiting a webpage to remember events or actions
        you have completed while on the site. This value is updated upon any
        subsequent visits. IP addresses tracked through Google Analytics are
        anonymized, per Google guidelines.
      </Typography>

      <Typography component="p" variant="subtitle1">
        Cookies strictly necessary for site functionality are those utilized to
        track a user’s opt-in status for the purpose of recording consent,
        selected location information from the last visited page to keep your
        desired location set and the user’s browser language to keep your
        preferred or selected content language in place across the website.
      </Typography>

      <Typography component="p" variant="subtitle1">
        If you opted into our tracking policy, but still wish to clear
        remarketing/retargeting pixels, these may be removed by following the
        instructions for cookie deletion for your specific web browser.
      </Typography>

      <Typography component="h3" variant="h3">
        Deleting Your Personal Information
      </Typography>

      <Typography component="p" variant="subtitle1">
        In ordinance with European Union GDPR guidelines, Personal Identifiable
        Information (PII) stored can be deleted or obtained per request by
        sending an email to{' '}
        <a href="mailto:dataadmin@twomen.com" className={classes.link}>
          dataadmin@twomen.com
        </a>{' '}
        and providing your name, email address and phone number. The request
        will be fulfilled within 30 days after any transactions pending with TWO
        MEN AND A TRUCK® have ended, including outstanding damage claims,
        pending moves, and/or outstanding payment. The request will include
        removal of personal information including name, email address, and phone
        number from all internal systems.
      </Typography>

      <Typography component="h3" variant="h3">
        External Links
      </Typography>

      <Typography component="p" variant="subtitle1">
        TWO MEN AND A TRUCK is not responsible for the privacy practices or
        content of any external websites to which it links to from this site.
        These other websites may collect their own cookies and data from users
        or solicit personally identifiable information that is beyond our
        control. You should consult each website’s privacy policy or contact the
        website administrators directly if you have any questions about use of
        the information they may be collecting.
      </Typography>

      <Typography component="h3" variant="h3">
        Call Tracking
      </Typography>

      <Typography component="p" variant="subtitle1">
        Call tracking is utilized to display dynamic phone numbers across our
        website. These help to identify the source that helped you find our
        website. Calls may be recorded for training purposes and any personal
        identifiable information is redacted within the recorded call file.
      </Typography>

      <Typography component="h3" variant="h3">
        Notification of Changes
      </Typography>

      <Typography component="p" variant="subtitle1">
        We reserve the right to revise our privacy policy at any time without
        advance notice. Any change to this policy will be posted on this site
        and the new policy will apply only to information collected following
        the posting of the new policy.
      </Typography>

      <Typography component="h3" variant="h3">
        Conflict Resolution
      </Typography>

      <Typography component="p" variant="subtitle1">
        We are committed to resolving disputes within five (5) business days. If
        problems arise, visitors may contact us by mail, email, telephone or
        fax.
      </Typography>

      <Typography component="h3" variant="h3">
        Website Administration/Contact Information
      </Typography>

      <Typography component="p" variant="subtitle1">
        <strong>Business name:</strong> TWO MEN AND A TRUCK®/INTERNATIONAL, Inc.{' '}
        <br />
        <strong>Address:</strong> 3400 Belle Chase Way <br />
        <strong>City:</strong> Lansing <br />
        <strong>State/Province:</strong> MI <br />
        <strong>ZIP:</strong> 48911 <br />
        <strong>Country:</strong> USA <br />
        <strong>Phone:</strong> 1-800-345-1070 <br />
        <strong>Fax:</strong> 517-394-7432 <br />
        <strong>Website URL:</strong>{' '}
        <a href="https://www.twomenandatruck.com" className={classes.link}>
          https://www.twomenandatruck.com
        </a>{' '}
        <br />
        <strong>Website e-mail:</strong>{' '}
        <a
          href="mailto:webmaster@twomenandatruck.com"
          className={classes.link}
        >
          webmaster@twomenandatruck.com
        </a>
      </Typography>
    </div>
    <div className={classes.footerChevronContainer}>
      <img
        src={withPrefix('/img/bottom--alt.svg')}
        alt=""
        className={classes.footerChevron}
      />
    </div>
  </Layout>
);

export default withRoot(withStyles(styles)(Privacy));
